'use client';

import { useRouter } from 'next/navigation';
import { HeroOmniSearch } from '@qb/frontend/components/OmniSearch/HeroOmniSearch';
import { TenantAliasProps } from '@qb/frontend/types/tenantAlias.types';
export const HeroOmniSearchComponent = ({
  tenantAlias
}: TenantAliasProps) => {
  const router = useRouter();
  return <HeroOmniSearch searchInputName="SPSHeroSearch" inputSx={{
    fontSize: 18,
    backgroundColor: 'common.white',
    height: {
      xs: 54,
      md: 59
    },
    pl: {
      xs: 1,
      md: 4
    },
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      fontSize: [24, 36],
      color: 'secondary.main'
    }
  }} magicIconSx={{
    width: 90,
    fontSize: {
      xs: 30,
      md: 50
    }
  }} tenantAlias={tenantAlias} handlerRedirect={href => router.push(href)} data-sentry-element="HeroOmniSearch" data-sentry-component="HeroOmniSearchComponent" data-sentry-source-file="HeroOmniSearchComponent.tsx" />;
};